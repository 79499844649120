import {
  query_service_session_list,
} from '@/api/service-model';
import { TableColumn } from '@/typing';
import { SearchConfig, Record } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY} from '@/store/mutation-type';
import {
  slotList,
  btn_list,
  head_btn_list,
  event_obj,
  config,
} from '@/components/page-model_kefu/service-service_session-config';

import { reactive } from 'vue';
import { create_country } from '@/api/baseinfo-model';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import { getDate, getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'rangepicker',
      name: 'start_end_date',
      label: '时间',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请选择时间',
    },
    {
      type: 'remoteselect',
      name: 'service_id',
      label: '客服',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'id',
      placeholder: '客服',
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: '选择客服',
      modalType: 'service-service',
    },
    // {
    //   type: 'remotemodal',
    //   name: 'adviser_user_id',
    //   label: '顾问',
    //   disabled: false,
    //   allowClear: true,
    //   defaultValue: '',
    //   labelKey: 'full_name',
    //   valueKey: 'adviser_user_id',
    //   placeholder: '隶属顾问',
    //   show_search: false,
    //   maxTagCount: 5,
    //   mode: 'default',
    //   title: '选择顾问',
    //   modalType: 'cerp-adviser',
    // },
    {
      type: 'input',
      name: 'name',
      label: '主题',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    adviser_user_id: null,
    service_id: null,
    start_end_date: null,
  }),
};
const search_options = {
  gender: [
    { value: 0, name: '男' },
    { value: 1, name: '女' },
  ],
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: { },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    // adviser_id: adviser.adviser_id,
    // name: null,
    // relation: null,
    // gender: null,
    // date_of_birth: null,
    // id_type_id: null,
    // id_number: null,
    // id_expiry_date: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [ ],
  rules: {},
  model: reactive({ }),
};
const update_model_config_options = {};

const titleList = {
  title: '受益人管理',
  addModalTitle: '新建受益人',
  updateTitle: '修改受益人',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: false, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    beneficiary_id: 'id',
  },
  delete: {
    beneficiary_id: 'id',
  },
  recover: {
    beneficiary_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    beneficiary_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.beneficiary.column.title.id'),
    minWidth: 150,
    dataIndex: 'id',
    checked: true,
    fixed: 'left',
    cellComponent: 'hui_hua',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_service_session_list,
    addData: null,
    updateData: null,
    removeData: null,
    recoverData: null,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
