
import { computed, createVNode, defineComponent, onMounted, onUnmounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import PageModel5 from '@/components/page-model_kefu/index.vue';
import { debounce } from 'lodash-es';
import mqtt_fun from '@/utils/service/mqtt';
import bus from '@/utils/bus';
import {
  close_service_session,
  create_session_record_for_service,
  deliver_service_session,
  query_service_list,
  query_service_session_list,
  query_session_record_list,
  set_session_record_read,
} from '@/api/service-model';
import { ExclamationCircleOutlined, PictureOutlined, ShareAltOutlined } from '@ant-design/icons-vue';
import { message, Modal, notification } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import { event_obj } from '@/components/page-model_kefu/service-service_session-config';
import { useI18n } from 'vue-i18n';
import { GET_NO_READ_NUMBER, GET_NO_RECEIVE_NUM } from '@/store/modules/user/actions';

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const service_no_read_num = computed(() => store.getters['user/no_read_number']);
    const service = computed(() => store.getters['user/service']);
    const bus_obj = bus;
    const sentence: any = ref('');
    const service__name: any = ref('');
    const adviser: any = ref('');
    const is_closed: any = ref(true);
    const is_access: any = ref(true);
    const is_assignment: any = ref(true);
    const is_search: any = ref(false);
    const is_pagination: any = ref(false);
    const dataSource: any = ref([]);
    let session_id: any = null;
    const search = reactive({
      current: 1,
      pageSize: 1000,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        org_id: current_org.id,
        is_active: true,
        service_id: null,
        is_no_service: true,
        is_closed: false,
      },
    });
    const tableHeight = reactive({ y: window.innerHeight - 170, y1: window.innerHeight - 353 });
    const onresize: any = debounce(() => {
      tableHeight.y = window.innerHeight - 170;
      tableHeight.y1 = window.innerHeight - 353;
    }, 200);
    const key = ref('request_tab');
    const tabList = [
      {
        key: 'request_tab',
        tab1: t('未接入'),
        slots: { tab: 'request_tab' },
      },
      {
        key: 'my_tab',
        tab1: t('我的'),
        slots: { tab: 'my_tab' },
      },
      {
        key: 'all_tab',
        tab1: t('所有'),
        slots: { tab: 'all_tab' },
      },
      {
        key: 'history_tab',
        tab1: t('历史'),
        slots: { tab: 'history_tab' },
      },
    ];
    const mqtt_data = reactive({});

    const no_receive_num: any = ref(0);
    const get_no_receive_num = () => {
      query_service_session_list({
        org_id: current_org?.id,
        is_no_service: true,
        is_auto_reply: false,
        is_closed: false,
        pageSize: 20000,
      }).then((res: any) => {
        if (res && res.data && res.data.length > 0) {
          no_receive_num.value = res.data.length;
        } else {
          no_receive_num.value = 0;
        }
      });
      store.dispatch(`user/${GET_NO_RECEIVE_NUM}`,
        {
          org_id: Number(current_org?.id),
          is_no_service: true,
          is_auto_reply: false,
          is_closed: false,
        });
    };
    get_no_receive_num();  // 未接收数

    onMounted(() => {
      mqtt_fun().init();
      window.onresize = onresize;
      onresize();
      bus_obj.on('service_read', refresh_service_read); // 客服已读
      bus_obj.on('adviser_read', refresh_adviser_read); // 顾问已读
      bus_obj.on('recieve_session', refresh_recieve_session); // 转客服 或 自己接收
      bus_obj.on('chat_to_adviser', refresh_chat_to_adviser); // 客服发送给顾问
      bus_obj.on('close_session', refresh_close_session); // 关闭会话
      bus_obj.on('chat_to_service', refresh_chat_to_service);  // 顾问发送
      mqtt_fun().channel_subscribe_event('', 'adviser_read');
      mqtt_fun().channel_subscribe_event('', 'chat_to_service');
      mqtt_fun().channel_subscribe_event('', 'service_read');
      mqtt_fun().channel_subscribe_event('', 'recieve_session');
      mqtt_fun().channel_subscribe_event('', 'close_session');
      mqtt_fun().channel_subscribe_event('', 'chat_to_adviser');
    });

    onUnmounted(() => {
      mqtt_fun().channel_unsubscribe_event('', 'adviser_read');
      mqtt_fun().channel_unsubscribe_event('', 'chat_to_service');
      mqtt_fun().channel_unsubscribe_event('', 'service_read');
      mqtt_fun().channel_unsubscribe_event('', 'recieve_session');
      mqtt_fun().channel_unsubscribe_event('', 'close_session');
      mqtt_fun().channel_unsubscribe_event('', 'chat_to_adviser');
      bus_obj.off('service_read', refresh_service_read);
      bus_obj.off('adviser_read', refresh_adviser_read);
      bus_obj.off('recieve_session', refresh_recieve_session);
      bus_obj.off('chat_to_adviser', refresh_chat_to_adviser);
      bus_obj.off('close_session', refresh_close_session);
      bus_obj.off('chat_to_service', refresh_chat_to_service);
    });
    const refresh_service_read = (data: any) => {
      if (data) {
        console.log('客服已读', data);
        Object.assign(mqtt_data, { mqtt_type: 'service_read', ...data });
        bus.emit('mqtt_update', mqtt_data);
        store.dispatch(`user/${GET_NO_READ_NUMBER}`, { org_id: Number(current_org?.id) });
      }
    };
    const refresh_adviser_read = (data: any) => {
      if (data) {
        console.log('顾问已读', data);
        if (searchConfig.model.session_id == data.session_id) {
          dataSource.value.map((item1: any) => {
            item1.is_adviser_read = true;
          });
          dataSource.value = ([] as any).concat(dataSource.value);
          Object.assign(mqtt_data, { mqtt_type: 'service_read', ...data });
          bus.emit('mqtt_update', mqtt_data);
        }
      }
    };
    const refresh_recieve_session = (data: any) => {
      if (data) {
        console.log('客服接/转客服', data);
        if (key.value == 'request_tab') {
          Object.assign(mqtt_data, { mqtt_type: 'recieve_session_request_tab', ...data });
          bus.emit('mqtt_update', mqtt_data);
        } else if (key.value == 'all_tab') {
          Object.assign(mqtt_data, { mqtt_type: 'recieve_session_all_tab', ...data });
          bus.emit('mqtt_update', mqtt_data);
        } else if (key.value == 'my_tab') {
          if (service.value.id == data.service_id) {
            Object.assign(mqtt_data, { mqtt_type: 'recieve_session_may_tab', ...data });
            bus.emit('mqtt_update', mqtt_data);
          } else if (service.value.id != data.service_id) {
            Object.assign(mqtt_data, { mqtt_type: 'recieve_session_may_tab_no', ...data });
            bus.emit('mqtt_update', mqtt_data);
          }
        }
        get_no_receive_num();  // 未接收数
      }
    };
    const refresh_chat_to_adviser = (data: any) => {
      if (data) {
        console.log('客服发顾问', data);
        if (searchConfig.model.session_id == data.session_id) {
          dataSource.value = dataSource.value.concat([{ ...data.session_record }]);
          Object.assign(mqtt_data, { mqtt_type: 'chat_to_adviser_now', ...data });
          bus.emit('mqtt_update', mqtt_data);
        }
        goto_timeout();
      }
    };
    const refresh_chat_to_service = (data: any) => {
      console.log('顾问发送', data);
      if (data) {
        if (searchConfig.model.session_id == data.session_id) {
          dataSource.value = dataSource.value.concat([{ ...data.session_record }]);
          goto_timeout();
          Object.assign(mqtt_data, { mqtt_type: 'chat_to_service', ...data });
          bus.emit('mqtt_update', mqtt_data);
          set_session_record_read({
            org_id: searchConfig.model.org_id,
            session_id: searchConfig.model.session_id,
            session_record_ids: [data.session_record.id],
          });
        } else {
          if (data.service_id) {
            if (key.value == 'my_tab') {
              Object.assign(mqtt_data, { mqtt_type: 'chat_to_service', ...data });
              bus.emit('mqtt_update', mqtt_data);
            }
            store.dispatch(`user/${GET_NO_READ_NUMBER}`, { org_id: Number(current_org?.id) });
          } else {
            if (key.value == 'request_tab') {
              Object.assign(mqtt_data, { mqtt_type: 'add_service_request_tab', ...data });
              bus.emit('mqtt_update', mqtt_data);
            }
            get_no_receive_num();  // 未接收数
          }
        }
        goto_timeout();
      }
    };
    const refresh_close_session = (data: any) => {
      if (data) {
        console.log('关闭会话', data);
        if (key.value != 'history_tab') {
          Object.assign(mqtt_data, { mqtt_type: 'close_session', ...data });
          if (searchConfig.model.session_id == data.session_id) {
            is_closed.value = true;
            dataSource.value.length = 0;
            searchConfig.model.session_id = null;
          }
          bus.emit('mqtt_update', mqtt_data);
        }
      }
    };

    const searchConfig: any = {
      model: {
        org_id: current_org?.id,
        is_active: true,
        pageSize: 50,
        // adviser_user_id: adviser.adviser_user_id,
      },
    };
    const data: any = ref([]);
    for (let i = 0; i < 1000; i++) {
      data.value.push({
        key: i,
        name: `Edrward ${i}`,
        age: i + 1,
        address: `London Park no. ${i}`,
      });
    }
    const columns: any = [
      {
        title: 'id',
        width: 60,
        dataIndex: 'id',
        autoHeight: true,
        cellComponent: 'name',
        content: (record: any) => {
          if (!record.is_service) {
            return true;
          }
        },
        cb: (record: any) => {
          if (!record.is_service) {
            return t('顾问');
          }
        },
      },
      {
        title: 'name',
        minWidth: 150,
        dataIndex: 'name',
        autoHeight: true,
        cellComponent: 'talk',
        content: (record: any) => {
          if (!record.is_service) {
            return true;
          }
        },
      },
      {
        title: 'content',
        width: 60,
        dataIndex: 'content',
        autoHeight: true,
        cellComponent: 'name',
        content: (record: any) => {
          if (record.is_service) {
            return true;
          }
        },
        cb: (record: any) => {
          if (record.is_service && !record.is_auto_reply) {
            return t('客服');
          } else if (record.is_service && record.is_auto_reply) {
            return t('机器人');
          }
        },
      },
    ];
    const query_session = (is_can_read: any) => {
      query_session_record_list({
        ...searchConfig.model,
      }).then((res: any) => {
        let ids: any = [];
        dataSource.value.length = 0;
        if (res && res.data && res.data.length > 0) {
          res.data.map((item: any) => {
            dataSource.value.push({ ...item });
            if (!item.is_service_read) {
              ids.push(item.id);
            }
          });
          if (ids.length > 0 && is_can_read) {
            set_session_record_read({
              org_id: searchConfig.model.org_id,
              session_id: searchConfig.model.session_id,
              session_record_ids: ids,
            });
          }
          if (dataSource.value.length > 49) {
            dataSource.value = dataSource.value.sort((a: any, b: any) => a.id - b.id);
            dataSource.value = ([{
              id: 0,
              name: dataSource.value[0].name,
              content: dataSource.value[0].name,
              start_id: dataSource.value[0].id,
            }] as any).concat(dataSource.value);
          } else {
            dataSource.value = dataSource.value.sort((a: any, b: any) => a.id - b.id);
            dataSource.value = ([] as any).concat(dataSource.value);
          }
        }
        goto_timeout();
      });
    };
    const query_session_more = (id: any) => {
      query_session_record_list({
        start_id: id,
        org_id: searchConfig.model.org_id,
        session_id: searchConfig.model.session_id,
      }).then((res: any) => {
        if (res && res.data && res.data.length > 0) {
          let id_list: any = [];
          res.data.map((item: any) => {
            if (!item.is_adviser_read) {
              id_list.push(item.id);
            }
          });
          if (id_list.length > 0) {
            set_session_record_read({
              org_id: searchConfig.model.org_id,
              session_id: searchConfig.model.session_id,
              session_record_ids: id_list,
            });
          }
          let list_more: any = res.data.sort((a: any, b: any) => a.id - b.id);
          dataSource.value.splice(0, 1);
          dataSource.value = (list_more as any).concat(dataSource.value);
          dataSource.value = ([{
            id: 0,
            name: dataSource.value[0].name,
            content: dataSource.value[0].name,
            start_id: dataSource.value[0].id,
          }] as any).concat(dataSource.value);
        } else {
          message.error(t('无更多信息加载'));
        }
      });
    };
    const service_session: any = reactive({
      title: t('客服会话'),
      column_flag: 'service-service_session',
      search: { ...search, requestParams: { ...search.requestParams, num: 0, is_auto_reply: false } },
      modalType: 'service-service_session',
      height: tableHeight.y,
      mode: 'radio',
    });

    const getFangan = (record: any) => {
      searchConfig.model.session_id = record.id;
      service__name.value = record.service__name;
      adviser.value = record.adviser;
      is_closed.value = record.is_closed;
      is_access.value = false;
      is_assignment.value = false;
      if (key.value == 'all_tab' || key.value == 'request_tab') {
        is_closed.value = true;
      }
      if (!record.service_id && service.value.id) {  // 未接入
        query_session(false);
        is_access.value = true;
        is_assignment.value = true;
      } else {
        if (key.value == 'all_tab' || key.value == 'history_tab' || key.value == 'request_tab') {
          query_session(false);
        } else if (key.value == 'my_tab') {
          is_closed.value = false;
          query_session(true);
        }
      }
    };
    const send_out = () => {
      if (sentence.value) {
        if (service.value.status == 1) {
          create_session_record_for_service({
            org_id: current_org.id,
            content: sentence.value,
            message_type: 0,
            session_id: searchConfig.model.session_id,
          }).then((res: any) => {
            if (res) {
              sentence.value = '';
            }
          });
        } else {
          notification.error({
            message: t('温馨提示'),
            description:
              t('当前处在‘下班’状态，请上班后操作。'),
          });
          sentence.value = '';
        }
      } else {
        notification.error({
          message: t('温馨提示'),
          description:
            t('不可发送空白消息'),
        });
        sentence.value = '';
      }
    };
    const send_out_enter = (e: any) => {
      if (service.value.status == 1) {
        if (e.ctrlKey && e.keyCode == 13) {
          sentence.value += '\n';
        } else if (e.shiftKey && e.keyCode == 13) {
          sentence.value += '\n';
        } else if (e.altKey && e.keyCode == 13) {
          sentence.value += '\n';
        } else {
          if (sentence.value != '\n') {
            create_session_record_for_service({
              org_id: current_org.id,
              content: sentence.value,
              message_type: 0,
              session_id: searchConfig.model.session_id,
            }).then((res: any) => {
              if (res) {
                sentence.value = '';
              }
            });
          } else {
            notification.error({
              message: t('温馨提示'),
              description:
                t('不可发送空白消息'),
            });
            sentence.value = '';
          }
        }
      } else {
        notification.error({
          message: t('温馨提示'),
          description:
            t('当前处在‘下班’状态，请上班后操作。'),
        });
        sentence.value = '';
      }
    };
    const Send_link = () => {
      if (!service.value.id) {
        notification.error({
          message: t('温馨提示'),
          description: t('当前账号无客服权限'),
        });
        return false;
      }
      if (is_closed.value) {
        notification.error({
          message: t('温馨提示'),
          description: t('当前不可发送链接'),
        });
        return false;
      }
      const handleHttp = (rule: any, value: any) => {
        return new Promise((resolve, reject) => {
          console.log('value', value);
          const regex = /(http|https):\/\/([\w.]+\/?)\S*/;
          if (regex.test(value)) {
            resolve(null);
          } else {
            reject(t('请写http/https开头的链接'));
          }
        });
      };
      if (service.value.status == 1) {
        const editModal = reactive({
          visible: true,
          title: t('链接发送'),
          loading: false,
          form: {
            settings: {
              labelAlign: 'right',
              layout: 'vertical',
              col: [],
            },
            fields: [
              {
                type: 'input',
                name: 'name',
                label: '链接',
                label_i18n: '链接',
                placeholder_i18n: '请填写链接内容',
                disabled: false,
                allowClear: true,
                inputType: 'text',
                defaultValue: '',
                placeholder: '请填写链接内容',
              },
            ],
            rules: {
              name: [
                { required: true, message: t('请填写链接内容') },
                { validator: handleHttp, trigger: ['change'] },
              ],
            },
            model: reactive({
              org_id: current_org?.id,
              name: null,
            }),
          },
          options: {},
          ok: (payload: any) => {
            return new Promise(resolve => {
              editModal.loading = true;
              create_session_record_for_service({
                org_id: current_org.id,
                content: payload.name,
                message_type: 2,
                session_id: searchConfig.model.session_id,
              }).then((res: any) => {
                if (res) {
                  resolve(null);
                }
              }).finally(() => {
                editModal.loading = false;
              });
            });
          },
        });
        modalBox(editModal);
      } else {
        notification.error({
          message: t('温馨提示'),
          description:
            t('当前处在‘下班’状态，请上班后操作。'),
        });
      }
    };
    const UploadFile = () => {
      if (!service.value.id) {
        notification.error({
          message: t('温馨提示'),
          description: t('当前账号无客服权限'),
        });
        return false;
      }
      if (is_closed.value) {
        notification.error({
          message: t('温馨提示'),
          description: t('当前不可发送图片'),
        });
        return false;
      }
      if (service.value.status == 1) {
        const loading = ref(false);
        const editModal = reactive({
          visible: true,
          title: t('图片发送'),
          group: '图片',
          type: 'picture-card',
          component: 'upload-image-kefu-modal',
          loading: loading,
          update: (payload: any) => {
            return new Promise(resolve => {
              loading.value = true;
              create_session_record_for_service({
                org_id: current_org.id,
                content: payload[0],
                message_type: 1,
                session_id: searchConfig.model.session_id,
              }).then((res: any) => {
                if (res) {
                  resolve(null);
                }
              }).finally(() => {
                editModal.loading = false;
              });
            });
          },
        });
        modalBox(editModal);
      } else {
        notification.error({
          message: t('温馨提示'),
          description:
            t('当前处在‘下班’状态，请上班后操作。'),
        });
      }
    };
    const close_talk = () => {
      Modal.confirm({
        title: t('确认关闭会话') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            close_service_session({
              org_id: current_org.id,
              service_session_id: searchConfig.model.session_id,
            }).then((res: any) => {
              if (res) {
                is_closed.value = false;
                resolve(null);
              }
            });
          });
        },
        onCancel() {
        },
      });
    };
    const stable: any = ref(null);
    const goto = () => {
      if (dataSource.value && dataSource.value.length > 0) {
        stable.value.scrollTo({ rowKey: dataSource.value[dataSource.value.length - 1].id });
      }
    };
    const goto_timeout = () => {
      setTimeout(() => {
        goto();
      }, 600);
      setTimeout(() => {
        goto();
      }, 800);
      setTimeout(() => {
        goto();
      }, 1000);
    };
    const customCell = () => {
      return { style: { background: '#F1F2F3' } };
    };

    const onTabChange = (value: string) => {
      let old_key: any = '' + key.value;
      key.value = value;
      if (value == 'all_tab') {
        is_search.value = false;
        is_pagination.value = false;
        service_session.search.tags = 'all_tab';
        service_session.search.requestParams.is_no_service = false;
        service_session.search.requestParams.service_id = null;
        service_session.search.requestParams.is_auto_reply = null;
        service_session.search.requestParams.is_closed = false;
        service_session.search.requestParams.num += 1;
        bus.emit('mqtt_update', { mqtt_type: 'update_selected_id' });
        service__name.value = '';
        is_closed.value = true;
        dataSource.value.length = 0;
        searchConfig.model.session_id = null;
      } else if (value == 'my_tab') {
        if (service.value.id) {
          is_search.value = false;
          is_pagination.value = false;
          service_session.search.tags = 'my_tab';
          service_session.search.requestParams.service_id = service.value.id;
          service_session.search.requestParams.is_no_service = false;
          service_session.search.requestParams.is_auto_reply = null;
          service_session.search.requestParams.is_closed = false;
          if (old_key == 'all_tab' || old_key == 'history_tab') {
            is_closed.value = true;
            dataSource.value.length = 0;
            searchConfig.model.session_id = null;
          } else {
            // is_access.value = false;
          }
        } else {
          bus.emit('mqtt_update', { mqtt_type: 'no_service_my_tab' });
          dataSource.value.length = 0;
          searchConfig.model.session_id = null;
        }
      } else if (value == 'request_tab') {
        is_search.value = false;
        is_pagination.value = false;
        service_session.search.tags = 'request_tab';
        service_session.search.requestParams.service_id = null;
        service_session.search.requestParams.is_no_service = true;
        service_session.search.requestParams.is_closed = false;
        service_session.search.requestParams.is_auto_reply = false;
        service_session.search.requestParams.num += 1;
      } else if (value == 'history_tab') {
        is_search.value = true;
        is_pagination.value = true;
        service_session.search.tags = 'history_tab';
        service_session.search.requestParams.service_id = null;
        service_session.search.requestParams.is_no_service = false;
        service_session.search.requestParams.is_auto_reply = null;
        service_session.search.requestParams.is_closed = true;
        service_session.search.requestParams.num += 1;
        service__name.value = '';
        is_closed.value = true;
        dataSource.value.length = 0;
        searchConfig.model.session_id = null;
      }
    };
    const transfer_kefu = () => {
      const editModal = reactive({
        visible: true,
        title: t('转客服'),
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
          },
          fields: [
            {
              type: 'remoteselect',
              name: 'service_id',
              label: '在线客服',
              label_i18n: '在线客服',
              placeholder_i18n: '在线客服',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'id',
              init_model: { status: 1 },
              placeholder: '客服',
              show_search: true,
              maxTagCount: 5,
              allowClear: true,
              mode: 'default',
              title: t('选择客服'),
              modalType: 'service-service',
            },
            {
              type: 'remoteselect',
              name: 'service_id1',
              label: '离线客服',
              label_i18n: '离线客服',
              placeholder_i18n: '离线客服',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'id',
              init_model: { status: 2 },
              placeholder: '客服',
              show_search: true,
              maxTagCount: 5,
              allowClear: true,
              mode: 'default',
              title: t('选择客服'),
              modalType: 'service-service',
            },
          ],
          rules: {},
          model: {
            service_id: null,
            service_id1: null,
            service_name: null,
          },
          watch: {
            service_id: (data: any) => {
              if (data.service_id && data.service_id1) {
                notification.error({
                  message: t('温馨提示'),
                  description:
                    t('只可选择一位客服进行转接'),
                });
              } else {
                if (data.service_id) {
                  query_service_list({
                    org_id: current_org?.id,
                    ids: [data.service_id],
                  }).then((res: any) => {
                    if (res && res.data && res.data.length > 0) {
                      data.service_name = res.data[0].name;
                    }
                  });
                } else if (data.service_id1) {
                  query_service_list({
                    org_id: current_org?.id,
                    ids: [data.service_id1],
                  }).then((res: any) => {
                    if (res && res.data && res.data.length > 0) {
                      data.service_name = res.data[0].name;
                    }
                  });
                } else {
                  data.service_name = null;
                }
              }
            },
            service_id1: (data: any) => {
              if (data.service_id && data.service_id1) {
                notification.error({
                  message: t('温馨提示'),
                  description:
                    t('只可选择一位客服进行转接'),
                });
              } else {
                if (data.service_id1) {
                  query_service_list({
                    org_id: current_org?.id,
                    ids: [data.service_id1],
                  }).then((res: any) => {
                    if (res && res.data && res.data.length > 0) {
                      data.service_name = res.data[0].name;
                    }
                  });
                } else if (data.service_id) {
                  query_service_list({
                    org_id: current_org?.id,
                    ids: [data.service_id],
                  }).then((res: any) => {
                    if (res && res.data && res.data.length > 0) {
                      data.service_name = res.data[0].name;
                    }
                  });
                } else {
                  data.service_name = null;
                }
              }
            },
          },
        },
        options: {},
        ok: (data: any) => {
          return new Promise(resolve => {
            const service_id: any = data.service_id ? data.service_id : data.service_id1 ? data.service_id1 : null;
            if (service_id) {
              deliver_service_session({
                org_id: current_org?.id,
                content: '[' + service.value.name + ']' + t('转给') + '[' + data.service_name + ']',
                service_id: service_id,
                service_session_id: searchConfig.model.session_id,
              }).then(() => {
                service__name.value = '';
                is_closed.value = true;
                dataSource.value.length = 0;
                searchConfig.model.session_id = null;
                message.success(t('转客服成功'));
                resolve(null);
              });
            } else {
              notification.error({
                message: t('温馨提示'),
                description:
                  t('请选择一位客服进行转接'),
              });
            }


          });
        },
      });
      modalBox(editModal);
    };
    const assignment_kefu = () => {
      const editModal = reactive({
        visible: true,
        title: t('分配客服接入'),
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
          },
          fields: [
            {
              type: 'remoteselect',
              name: 'service_id',
              label: '在线客服',
              label_i18n: '在线客服',
              placeholder_i18n: '在线客服',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'id',
              init_model: { status: 1 },
              placeholder: '在线客服',
              show_search: true,
              maxTagCount: 5,
              allowClear: true,
              mode: 'default',
              title: t('选择客服'),
              modalType: 'service-service',
            },
            {
              type: 'remoteselect',
              name: 'service_id1',
              label: '离线客服',
              label_i18n: '离线客服',
              placeholder_i18n: '离线客服',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'id',
              init_model: { status: 2 },
              placeholder: '离线客服',
              show_search: true,
              maxTagCount: 5,
              allowClear: true,
              mode: 'default',
              title: t('选择客服'),
              modalType: 'service-service',
            },
          ],
          rules: {},
          model: {
            service_id: null,
            service_id1: null,
            service_name: null,
          },
          watch: {
            service_id: (data: any) => {
              if (data.service_id && data.service_id1) {
                notification.error({
                  message: t('温馨提示'),
                  description:
                    t('只可选择一位客服进行接入'),
                });
              } else {
                if (data.service_id) {
                  query_service_list({
                    org_id: current_org?.id,
                    ids: [data.service_id],
                  }).then((res: any) => {
                    if (res && res.data && res.data.length > 0) {
                      data.service_name = res.data[0].name;
                    }
                  });
                } else if (data.service_id1) {
                  query_service_list({
                    org_id: current_org?.id,
                    ids: [data.service_id1],
                  }).then((res: any) => {
                    if (res && res.data && res.data.length > 0) {
                      data.service_name = res.data[0].name;
                    }
                  });
                } else {
                  data.service_name = null;
                }
              }
            },
            service_id1: (data: any) => {
              if (data.service_id && data.service_id1) {
                notification.error({
                  message: t('温馨提示'),
                  description:
                    t('只可选择一位客服进行接入'),
                });
              } else {
                if (data.service_id1) {
                  query_service_list({
                    org_id: current_org?.id,
                    ids: [data.service_id1],
                  }).then((res: any) => {
                    if (res && res.data && res.data.length > 0) {
                      data.service_name = res.data[0].name;
                    }
                  });
                } else if (data.service_id) {
                  query_service_list({
                    org_id: current_org?.id,
                    ids: [data.service_id],
                  }).then((res: any) => {
                    if (res && res.data && res.data.length > 0) {
                      data.service_name = res.data[0].name;
                    }
                  });
                } else {
                  data.service_name = null;
                }
              }
            },
          },
        },
        options: {},
        ok: (data: any) => {
          return new Promise(resolve => {
            const service_id: any = data.service_id ? data.service_id : data.service_id1 ? data.service_id1 : null;
            if (service_id) {
              deliver_service_session({
                org_id: current_org?.id,
                content: '[' + data.service_name + ']' + t('接入'),
                service_id: service_id,
                service_session_id: searchConfig.model.session_id,
              }).then(() => {
                service__name.value = '';
                is_closed.value = true;
                dataSource.value.length = 0;
                searchConfig.model.session_id = null;
                message.success(t('分配成功'));
                resolve(null);
              });
            }
          });
        },
      });
      modalBox(editModal);
    };
    const Access_kefu = () => {
      if (service.value.status == 1) {
        Modal.confirm({
          title: t('确认接入会话') + '?',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              deliver_service_session({
                org_id: current_org?.id,
                service_id: service.value.id,
                service_session_id: searchConfig.model.session_id,
              }).then(() => {
                onTabChange('my_tab');
                query_session(true);
                is_access.value = false;
                message.success(t('接入成功'));
                get_no_receive_num();  // 未接收数
                resolve(null);
              });
            });
          },
          onCancel() {
          },
        });
      } else {
        notification.error({
          message: t('温馨提示'),
          description:
            t('当前处在‘下班’状态，请上班后操作。'),
        });
      }
    };

    return {
      t,
      dataSource,
      columns,
      data,
      send_out,
      send_out_enter,
      is_closed,
      service__name,
      adviser,
      service_session,
      tableHeight,
      Access_kefu,
      stable,
      goto,
      close_talk,
      UploadFile,
      Send_link,
      getFangan,
      searchConfig,
      event_obj,
      customCell,
      query_session_more,
      is_access,
      is_assignment,
      sentence,
      service,
      key,
      is_search,
      is_pagination,
      tabList,
      service_no_read_num,
      no_receive_num,
      onTabChange,
      transfer_kefu,
      assignment_kefu,
    };
  },
  components: {
    PageModel5,
    ShareAltOutlined,
    PictureOutlined,
  },
});
