
import { computed, createVNode, defineComponent, onActivated, onDeactivated, reactive, ref, watch } from 'vue';
import {
  BarChartOutlined,
  ColumnHeightOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import { TableColumn } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import FormModal from '@/components/form-modal/form-modal.vue';
import { useStore } from 'vuex';
import { useForm } from 'ant-design-vue/es/form';
import { message, Modal } from 'ant-design-vue';
import getPageConfig from '@/components/page-model_kefu/index';
import { Record } from '@/components/page-model/typing';
import bus from '@/utils/bus';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PageModelKefu',
  props: {
    modalType: {
      type: String,
      required: true,
    },
    search: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    column_flag: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: false,
    },
    rowkey: {
      type: String,
      required: false,
      default: () => 'id',
    },
    rowheight: {
      type: [String, Number],
      required: false,
    },
    virtual: {
      type: Boolean,
      default: () => false,
    },
    position: {
      type: Array,
      default: () => ['bottomRight'],
    },
    height: {
      type: Number,
      required: false,
      default: () => 0,
    },
    height2: {
      type: Number,
      required: false,
      default: () => 0,
    },
    is_show_all_column: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    is_show_action: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    is_show_excel: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    is_show_refresh: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    is_show_change_column: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    is_show_fullscreen: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    data_change_timeline: {
      type: Number,
      default: () => 0,
    },
    headerComponentObj: {
      type: Object,
      default: () => {},
    },
    is_pagination: {
      type: Boolean,
      default: () => false,
    },
    is_search: {
      type: Boolean,
      default: () => false,
    },
    is_show_toolbar: {
      type: Boolean,
      default: () => true,
    },
    is_custom_pagination: {
      type: Boolean,
      default: () => false,
    },
    is_show_table_btn: {
      type: Boolean,
      default: () => false,
    },
    is_tooltip_show: {
      type: Boolean,
      default: () => true,
    },
    outer_columns: {
      type: Array || Boolean,
      default: () => null,
      required: false,
    },
    showHeader: {
      type: Boolean,
      default: () => true,
      required: false,
    },
  },
  emits: ['ok', 'onLoad', 'refresh'],
  setup(props, { emit }) {
    const confirmLoading = ref<boolean>(false);
    const { t } = useI18n();
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const sizeChangeCount = ref(0);
    const selected_id = ref(0);
    const is_show_table = ref(true);
    const elToolbar: any = ref();
    const elSearch: any = ref();
    const select_model: any = reactive({
      org_id: current_org?.id,
      pageSize: 20,
    });
    const is_virtual = ref(false);
    const field = {
      column_flag: 'column_' + props.column_flag,
    };
    const summaryColumns = computed(() => {
      return dynamicColumns.value
        .filter((item: any) => {
          if (item.checked === true) {
            return true;
          } else if (item.checked === undefined) {
            return true;
          }
          return false;
        })
        .map((item: any, index: number) => {
          const obj = {
            index: index,
            columnKey: item.dataIndex,
            summary: item.summary,
            color: item.color,
            content: item.content,
          };
          return obj;
        });
    });

    const summaryColumnsShow = computed(() => {
      return dynamicColumns.value.filter(item => item.summary).length > 0;
    });

    const tableHeight = computed(() => {
      if (sizeChangeCount.value >= 0) {
        if (props.height2 == 0) {
          return props.height;
        } else {
          let h: number = props.height2;
          if (elSearch.value) {
            h -= elSearch.value.offsetHeight;
          }
          if (elToolbar.value) {
            h -= elToolbar.value.offsetHeight;
          }
          if (props.is_pagination) {
            h -= 60;
          }
          return h;
        }
      }
    });

    let formModalUpdate = false; // 判断显示创建、修改
    // 时间区间格式转化 数组-->字符串
    const getRangePicker = (api_fields: Record, record: Record) => {
      const obj: any = {};
      for (const key in api_fields) {
        if (record[api_fields[key] as string]) {
          obj[key] = record[api_fields[key] as string].join(',');
        }
      }
      return obj;
    };
    // 操作列 按钮分为下拉和普通
    const column_normal_btn_list: any = computed(() => {
      return btn_list?.filter((item: any) => item.type == undefined || item.type == 'btn');
    });
    const column_dropdown_btn_dict: any = computed(() => {
      const l = btn_list?.filter((item: any) => item.type == 'dropdown');
      if (l && l.length > 0) {
        return { dropdown_btn: l[0], btn_list: l.slice(1) };
      } else {
        return null;
      }
    });
    const {
      searchConfig,
      searchConfigOptions,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      queryData,
      addData,
      updateData,
      removeData,
      recoverData,
      baseColumns,
      column_list_change,
      slotList,
      titleList,
      api_fields,
      btn_list,
      head_btn_list,
      event_obj,
      init_request,
      selectedRowKeys,
      rowSelection,
      headerComponent,
      expandedRowRender,
      expandedRowRenderComponent,
      customCell,
      customRow,
      customHeaderCell,
      processData,
      processColumn,
      pageSize,
    } = getPageConfig(props.modalType);
    if (props.mode == 'multiple') {
      watch(
        () => selectedRowKeys,
        () => {
          emit('ok', selectedRowKeys.value);
        },
      );
    }

    if (searchConfig.fields) {
      for (let i = 0; i < searchConfig.fields.length; i++) {
        // 预先填充 placeholder
        let filed_dict = searchConfig.fields[i];
        if (!filed_dict.placeholder) {
          let pre_str = filed_dict.type == 'input' ? '请填写' : '请选择';
          filed_dict.placeholder = pre_str + filed_dict.label;
        }

        // 预先填充 校验message
        let search_rule_dict = searchConfig.rules[filed_dict.name];
        if (search_rule_dict) {
          if (!search_rule_dict.message) {
            search_rule_dict.message = filed_dict.placeholder;
          }
        }
      }
    }

    if (formModalConfig.fields) {
      for (let i = 0; i < formModalConfig.fields.length; i++) {
        let filed_dict = formModalConfig.fields[i];
        if (!filed_dict.placeholder) {
          let pre_str = filed_dict.type == 'input' ? '请填写' : '请选择';
          filed_dict.placeholder = pre_str + filed_dict.label;
        }
      }
    }

    const event_obj_reload = () => {
      reload();
      emit('refresh');
    };
    event_obj?.on('refresh', event_obj_reload);
    onActivated(() => {
      event_obj?.off('refresh', event_obj_reload);
      event_obj?.on('refresh', event_obj_reload);
    });
    onDeactivated(() => {
      event_obj?.off('refresh', event_obj_reload);
    });

    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
      configState,
      update_column_org_config,
      update_column_data,
      resizeColumn,
      switch_column_config,
      columnAction,
      column_change,
      replace_column,
    } = useTableDynamicColumns(
      props.outer_columns ? (props.outer_columns as TableColumn[]) : (baseColumns as TableColumn[]),
      {
        checkAll: false,
        needRowIndex: false,
      },
      store,
      field.column_flag,
      column_list_change,
    );

    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();
    const { resetFields, validateInfos } = useForm(searchConfig.model, searchConfig.rules);
    Object.keys(props.search.requestParams).map((key: string) => {
      if (searchConfig.fields.find((item: any) => item.name == key)) {
        searchConfig.model[key] = props.search.requestParams[key];
      }
    });

    const {
      stripe,
      reload: reload_data,
      setPageInfo,
      context: state,
    } = useFetchData(
      queryData,
      {
        current: 1,
        pageSize: pageSize ? pageSize : 1000,
        tableSize: 'small', // 'default' | 'middle' | 'small'
        ...props.search,
        init_request: !(init_request === false),
        requestParams: {
          ...props.search.requestParams,
          ...searchConfig.model,
        },
      },
      {
        onLoad: (dataSource: any[]) => {
          emit('onLoad', dataSource);
          // if (props.mode == 'radio' && state.dataSource.length > 0) {
          //   emit('ok', state.dataSource[0]);
          //   selected_id.value = state.dataSource[0][props.rowkey];
          // }
          if (state.dataSource.length > 1000) {
            is_virtual.value = true;
          }
        },
      },
      processData,
      processColumn,
      replace_column,
    );
    watch(
      () => props.data_change_timeline,
      () => {
        if (state.dataSource) {
          state.dataSource = [].concat(state.dataSource as any);
        }
      },
    );

    const reload = () => {
      let is_ok = true;
      if (searchConfig.need_fields) {
        for (let k of searchConfig.need_fields) {
          if (state.requestParams) {
            if (state.requestParams[k] === null || state.requestParams[k] === undefined) {
              is_ok = false;
            }
          }
        }
      }
      if (is_ok) {
        reload_data();
      }
    };

    // watch(
    //   () => state.dataSource,
    //   () => {
    //     state.dataSource.sort((a: any, b: any) => new Date(a).getTime() - new Date(b).getTime());
    //     state.dataSource = [].concat(state.dataSource as any);
    //   }
    // )

    watch(
      () => props.search.requestParams,
      () => {
        state.dataSource.length = 0;
        setPageInfo({
          current: state.current,
          pageSize: state.pageSize,
          ...searchConfig.model,
          ...props.search.requestParams,
          ...getRangePicker(api_fields.rangepicker, searchConfig.model),
        });
        reload();
      },
      { deep: true },
    );

    let sortFieldList: Array<string> = [];
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
      // console.log(pagination);
      let filter_map: any = {};
      let sort_fields: any = [];
      for (let key in filters) {
        filter_map[key] = filters[key].map((x: any) => x.toString()).join(',');
      }
      if (sorter instanceof Array) {
        sortFieldList = sortFieldList.filter((field: string) => {
          return sorter.find((item: any) => field == item.column.dataIndex) != undefined;
        });
        sorter.map((item: any) => {
          if (!sortFieldList.includes(item.column.dataIndex)) {
            sortFieldList.push(item.column.dataIndex);
          }
        });
        sort_fields = sortFieldList.map((field: string) => {
          if (sorter.find((item: any) => field == item.column.dataIndex).order == 'descend') {
            return `-${field}`;
          } else {
            return field;
          }
        });
      } else if (sorter != undefined && sorter.column != undefined) {
        sortFieldList.length = 0;
        sortFieldList.push(sorter.column.dataIndex);

        if (sorter.order == 'ascend') {
          sort_fields.push(sorter.column.dataIndex);
        }
        if (sorter.order == 'descend') {
          sort_fields.push('-' + sorter.column.dataIndex);
        }
      } else {
        sortFieldList.length = 0;
      }
      setPageInfo({
        ...props.search.requestParams,
        ...searchConfig.model,
        current: pagination?.current,
        pageSize: props.is_custom_pagination ? select_model.pageSize : pagination?.pageSize,
        ...getRangePicker(api_fields.rangepicker, searchConfig.model),
        ...filter_map,
        sort_fields: sort_fields,
      });
      reload();
    };

    // edit
    const editModalAdd = reactive({
      visible: false,
      title: '',
      form: formModalConfig,
      options: formModalConfigOptions,
    });
    const editModalUpdate = reactive({
      visible: false,
      title: '',
      form: updateFormModalConfig,
      options: updateFormModalConfigOptions,
    });
    let currentRecord = reactive({ name: '' });
    const handleOpenEdit = (record: any) => {
      formModalUpdate = true;
      editModalUpdate.visible = true;
      editModalUpdate.title = titleList.updateTitle;
      editModalUpdate.form.model = {
        ...updateFormModalConfig.model,
        ...record,
        ...getObjList(api_fields.update, record),
      };

      currentRecord = record;
    };
    const handleAdd = () => {
      formModalUpdate = false;
      editModalAdd.visible = true;

      editModalAdd.form.model = { ...formModalConfig.model, ...props.search.addParams };
      editModalAdd.title = titleList.addModalTitle;
    };

    const handleDelete = (record: any) => {
      Modal.confirm({
        title: () => '是否删除此项记录?',
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            removeData({
              org_id: current_org.id,
              ...getObjList(api_fields.delete, record),
            })
              .then((res: any) => {
                message.success(t('删除成功'));
                Object.assign(record, res);
                state.dataSource = ([] as any).concat(state.dataSource);
              })
              .finally(() => {
                resolve(null);
              });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    };
    const handleRecover = (record: any) => {
      recoverData({
        org_id: current_org.id,
        ...getObjList(api_fields.recover, record),
      }).then((res: any) => {
        message.success(t('恢复成功'));
        Object.assign(record, res);
        state.dataSource = ([] as any).concat(state.dataSource);
      });
    };

    const handlerOk = (data: any) => {
      confirmLoading.value = true;
      if (!formModalUpdate) {
        addData({ ...data })
          .then(() => {
            message.success(t('创建成功'));
            reload();
            editModalAdd.visible = false;
          })
          .finally(() => {
            confirmLoading.value = false;
            console.error('finally ');
          });
      } else {
        updateData({
          ...data,
          org_id: current_org.id,
          ...getObjList(api_fields.updateData, data),
          ...getObjList(api_fields.updateData, editModalUpdate.form.model),
        })
          .then((res: any) => {
            Object.assign(currentRecord, res);
            state.dataSource = ([] as any).concat(state.dataSource);
            message.success(t('修改成功'));
            editModalUpdate.visible = false;
            formModalUpdate = false;
          })
          .finally(() => {
            confirmLoading.value = false;
            console.error('finally ');
          });
      }
    };

    // 字段对应错误————>转化正确
    const getObjList = (api_fields: Record, record: Record) => {
      const obj: any = {};
      for (const key in api_fields) {
        if (record[api_fields[key] as string]) {
          obj[key] = record[api_fields[key] as string];
        }
      }
      return obj;
    };

    const custom = (record: any, index: any) => {
      if (customRow) {
        const res = customRow(record, index);
        if (res.onClick == undefined) {
          res.onClick = () => {
            if (props.mode == 'radio') {
              emit('ok', record);
              selected_id.value = record[props.rowkey];
            }
          };
        } else {
          res.onClick = (event: any) => {
            res.onClick(event);
            if (props.mode == 'radio') {
              emit('ok', record);
              selected_id.value = record[props.rowkey];
            }
          };
        }
        return res;
      } else {
        return {
          onClick: () => {
            if (props.mode == 'radio') {
              emit('ok', record);
              selected_id.value = record[props.rowkey];
            }
          },
        };
      }
    };
    const setRowClassName = (record: any) => {
      return record[props.rowkey] === selected_id.value ? 'mytable clickRowStyl6' : ''; //赋予点击行样式
    };
    const clearFilterAndOrder = (column: any) => {
      if (column.filteredValue != undefined) {
        column.filteredValue.length = 0;
      }
      if (column.sortOrder != undefined) {
        column.sortOrder = false;
      }

      if (column.children != undefined) {
        column.children.forEach((item: any) => clearFilterAndOrder(item));
      }
    };

    const search_list = () => {
      sortFieldList.length = 0;
      dynamicColumns.value.forEach((column: any) => clearFilterAndOrder(column));
      setPageInfo({
        current: 1,
        ...props.search.requestParams,
        ...searchConfig.model,
        ...getRangePicker(api_fields.rangepicker, searchConfig.model),
      });
      reload();
      handleReSize();
    };

    const handleReSize = () => {
      setTimeout(() => {
        sizeChangeCount.value += 1;
      }, 200);
    };
    watch(
      () => select_model,
      () => {
        setPageInfo({
          current: 1,
          ...props.search.requestParams,
          ...searchConfig.model,
          ...getRangePicker(api_fields.rangepicker, searchConfig.model),
          ...select_model,
        });
        reload();
      },
      { deep: true },
    );

    bus.on('mqtt_update', (mqtt_data: any) => {
      if (mqtt_data.mqtt_type == 'service_read') {
        state.dataSource.map((item: any)=>{
          if(mqtt_data.session_id==item.id ){
            item.no_read_num = 0;
          }
        });
        state.dataSource = [].concat(state.dataSource as any);
      } else if (mqtt_data.mqtt_type == 'adviser_read') {
        state.dataSource.map((item: any)=>{
          if(mqtt_data.session_id==item.id ){
            item.no_read_num = mqtt_data.no_read_num;
          }
        });
      } else if (mqtt_data.mqtt_type == 'recieve_session_request_tab') {
        state.dataSource.map((item: any, index: any)=>{
          if(mqtt_data.session_id==item.id ){
            state.dataSource.splice(index, 1);
          }
        });
      } else if (mqtt_data.mqtt_type == 'recieve_session_all_tab') {
        let is_query: any = true;
        state.dataSource.map((item: any) => {
          if(mqtt_data.session_id==item.id ){
            is_query = false;
          }
        })
        if (is_query) {
          state.dataSource = [{...mqtt_data.session, no_read_num: mqtt_data.service_no_read_num }].concat(state.dataSource as any);
        }
      } else if (mqtt_data.mqtt_type == 'recieve_session_may_tab') {
        let is_query: any = true;
        state.dataSource.map((item: any) => {
          if(mqtt_data.session_id==item.id ){
            is_query = false;
          }
        })
        if (is_query) {
          state.dataSource = [{...mqtt_data.session, no_read_num: mqtt_data.service_no_read_num}].concat(state.dataSource as any);
        }
      } else if (mqtt_data.mqtt_type == 'recieve_session_may_tab_no') {
        state.dataSource.map((item: any, index: any)=>{
          if(mqtt_data.session_id==item.id ){
            state.dataSource.splice(index, 1);
          }
        });
        selected_id.value = 0;
      } else if (mqtt_data.mqtt_type == 'update_selected_id') {
        selected_id.value = 0;
      } else if (mqtt_data.mqtt_type == 'recieve_session') {
        state.dataSource.map((item: any)=>{
          if(mqtt_data.session_id==item.id ){
            item.no_read_num = 0;
          }
        });
      } else if (mqtt_data.mqtt_type == 'chat_to_adviser') {
        state.dataSource.map((item: any)=>{
          if(mqtt_data.session_id==item.id ){
            item.content = mqtt_data.session_record.content;
            item.message_type = mqtt_data.session_record.message_type;
            item.update_time = mqtt_data.session_record.create_time;
          }
        });
      } else if (mqtt_data.mqtt_type == 'close_session') {
        state.dataSource.map((item: any, index: any)=>{
          if(mqtt_data.session_id==item.id ){
            state.dataSource.splice(index, 1);
          }
        });
      } else if (mqtt_data.mqtt_type == 'chat_to_service') {
        state.dataSource.map((item: any)=>{
          if(mqtt_data.session_id==item.id ){
            item.content = mqtt_data.session_record.content;
            item.message_type = mqtt_data.session_record.message_type;
            item.update_time = mqtt_data.session_record.create_time;
            item.no_read_num = mqtt_data.no_read_num;
          }
        });
      } else if (mqtt_data.mqtt_type == 'add_service_request_tab') {
        reload();
      } else if (mqtt_data.mqtt_type == 'no_service_my_tab') {
        state.dataSource.length = 0;
      } else if (mqtt_data.mqtt_type == 'chat_to_adviser_now') {
        state.dataSource.map((item: any)=>{
          if(mqtt_data.session_id==item.id ){
            item.content = mqtt_data.session_record.content;
            item.message_type = mqtt_data.session_record.message_type;
            item.update_time = mqtt_data.session_record.create_time;
            item.is_reply = true;
          }
        });
      }
      state.dataSource.sort((a: any, b: any) => new Date(b.update_time).getTime() - new Date(a.update_time).getTime());
      state.dataSource = [].concat(state.dataSource as any);
    })

    return {
      column_change,
      columnAction,
      setRowClassName,
      column_dropdown_btn_dict,
      column_normal_btn_list,
      slotList,
      titleList,
      state,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,
      summaryColumns,
      summaryColumnsShow,
      expandedRowRender,
      expandedRowRenderComponent,

      custom,
      is_virtual,
      is_show_table,
      customCell: customCell ? customCell : () => {},
      customHeaderCell: customHeaderCell ? customHeaderCell : () => {},
      handleAdd,
      handlerOk,
      search_list,
      handleReSize,
      confirmLoading,
      select_model,
      currentRecord,
      validateInfos,
      resetFields,
      elRef,
      screenState,
      setFull,
      exitFull,
      tableHeight,
      elToolbar,
      elSearch,

      handleTableChange,

      configState,
      // is_column_config,
      switch_column_config,
      // change_columns_config,
      update_column_org_config,
      update_column_data,
      resizeColumn,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      current_org,
      message,
      // edit
      editModalAdd,
      editModalUpdate,
      handleOpenEdit,
      handleDelete,
      handleRecover,
      reload,
      getPageConfig,
      searchConfig,
      searchConfigOptions,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      getObjList,
      api_fields,
      btn_list,
      head_btn_list,
      headerComponent,
      rowSelection: rowSelection ? rowSelection : null,
      selectedRowKeys: selectedRowKeys ? selectedRowKeys : null,
    };
  },
  components: {
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    DeleteOutlined,
    BarChartOutlined,
    Draggable,
    DragContainer,
    FormModal,
  },
});
